import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['input', 'form', 'submitted'];
  static values = { path: String };

  async send(ev) {
    const email = this.inputTarget.value;

    ev.preventDefault()

    if (email === '' || !/^[^@\s]+@[^@\s]+$/.test(email)) {
      this.inputTarget.value = '';
      this.inputTarget.placeholder = 'Enter a valid email';
      this.inputTarget.setAttribute("aria-invalid", "true");

      return;
    }

    const response = await fetch(this.pathValue, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    });

    this.submittedTarget.classList.remove('opacity-0');
    this.formTarget.classList.add('hidden');
    this.inputTarget.setAttribute("aria-invalid", "false");
  }
}
