import 'stylesheets/application.scss';
import 'stylesheets/root.scss';

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

const application = Application.start();
const context = require.context('controllers/content_pages', true, /\.js$/);

application.load(definitionsFromContext(context));
