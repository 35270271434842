import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['badges', 'icon'];

  connect() {
    this.badgesTarget.classList.add('overflow-hidden');

    setTimeout(() => {
      const denom = Math.ceil(this.badgesTarget.scrollWidth / this.badgesTarget.offsetWidth);

      if (denom === 1) {
        this.iconTargets.forEach(icon => icon.classList.add('hidden'));
      }

      this.scrollAmount = this.badgesTarget.scrollWidth / denom;
    }, 1);
  }

  scrollLeft() {
    this.badgesTarget.scrollLeft -= this.scrollAmount;
  }

  scrollRight() {
    this.badgesTarget.scrollLeft += this.scrollAmount;
  }
}
