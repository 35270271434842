import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['icon', 'form'];

  connect() {
    if (window.innerWidth < 420)
      return;

    this.closed = true

    this.iconTarget.classList.remove('hidden');
    this.iconTarget.setAttribute('aria-pressed', 'false');
    this.formTarget.style.width = 0;
  }

  toggle() {
    if (this.closed) {
      this.iconTarget.setAttribute('aria-pressed', 'true');
      this.formTarget.style.width = '280px';
    } else {
      this.iconTarget.setAttribute('aria-pressed', 'false');
      this.formTarget.style.width = 0;
    }

    this.closed = !this.closed;
  }
}
