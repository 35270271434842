import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['item'];

  connect() {
    if (window.innerWidth < 768)
      return;

    const imgs = this.itemTargets.map(item => {
      return new Promise(resolve => {
        const domImg = item.querySelector('img');
        const img = new Image();

        domImg.classList.add('opacity-0');

        img.src = domImg.src;
        img.onload = () => { resolve(domImg); };
        img.onerror = () => { resolve(domImg); };
      });
    });

    this.reappear(imgs);
  }

  async reappear(promises) {
    (await Promise.all(promises)).forEach((img, index) => {
      setTimeout(() => {
        img.classList.remove('opacity-0');
      }, index * 250);
    });
  }
}
